import React from "react"
import { Layout } from "../../components/Layout"
import { graphql } from "gatsby"
import { Subpage } from "../../components/Subpage"
import { Helmet } from "react-helmet"

const header = "Blog"
const description =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

export default function Blog({ data }) {
  const blogs = data.blogs.nodes

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakDevices - Blog</title>
        <meta
          name="description"
          content="Oakdevices - transform Your Business Into The Company Of The Future"
        />
      </Helmet>
      <Subpage header={header} description={description} elements={blogs} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPage {
    blogs: allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        tags
        slug
        category
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }
`
